import config from '../config/app';
import Rollbar from 'rollbar';

export const rollbar = new Rollbar({
    accessToken: config.rollbarToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
});

export const Context = React.createContext(rollbar);

export const Consumer = Context.Consumer;

export const Provider = ({ children }) => {
    return <Context.Provider value={rollbar}>{children}</Context.Provider>;
};
